/**
 * VideoOverlay
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class VideoOverlay
 * @param {object} props - Props
 */

import React from "react";



const VideoOverlay = () => {


		return (
			<div id="videoPlayer">
				<div className="video">
					<div id="videoPlayer--close" className="close">close</div>
					<div id="videoPlayer--container">
						<iframe 
							// className="videoPlayer--frame"
							title="Candy" width="640" height="360"
							src="" frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen="">
						</iframe>
					</div>
				</div>
			</div>
		);


}

export default VideoOverlay;
