import React from "react";

class FooterNewsletter extends React.Component {

	render() {
		return (
            <div className={"newsletter"}>
                <div className={'container'}>
                    <div>
                        <h2>{this.props.title}</h2>
                        <p>{this.props.description}</p>
                    </div>
                    <div>
                        <form>
                            <input type={'email'} placeholder={this.props.placeholder} required/>
							<button className={'cta-secondary'} type={'submit'}>{this.props.button}</button>
                        </form>
                    </div>
                </div>
            </div>
		);
	}

}

export default FooterNewsletter;
