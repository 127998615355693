/**
 * Header
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class Header
 * @param {object} props - Props
 */

import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from '../../atoms/link';
import Hamburger from '../../atoms/hamburger';
import Icon from "../../atoms/icon";
import Push from "../../atoms/headerPush";
import Promo from "../../atoms/headerPromo";
import Input from "../../atoms/form/input";
import WhereToBuy from "../../components/where-to-buy";


const getElement = (id) => document.getElementById(id);

const Menu = () => {
	// Hide Header on on scroll down
	let didScroll;
	let lastScrollTop = 0;
	let delta = 5;

	window.document.addEventListener('scroll', function (e) {
		didScroll = true;
	});


	setInterval(function () {
		if (didScroll) {
			HasScrolled();
			didScroll = false;
		}
	}, 250);

	const HasScrolled = () => {

		let st = document.body.scrollTop || document.documentElement.scrollTop;
		let dh = document.body.scrollHeight || document.documentElement.scrollHeight;
		let wh = window.innerHeight;

		// Make sure they scroll more than delta

		if (Math.abs(lastScrollTop - st) <= delta) return;
		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		const navBar = getElement('header')
		const mainWrapper = getElement('main')

		if (st > lastScrollTop && st > navBar.clientHeight) {
			// Scroll Down
			//navBar.removeClass("nav-down").addClass("nav-up");
			mainWrapper.classList.remove('navDown')
			mainWrapper.classList.add('navUp')
			setTimeout(function () {
				//$("body").addClass("hasScrolled");
				mainWrapper.classList.add('hasScrolled')
				closeAllItems()
			}, 250);
		} else {
			// Scroll Up

			if (st + wh < dh) {
				//navBar.removeClass("nav-up").addClass("nav-down");
				mainWrapper.classList.remove('navUp')
				mainWrapper.classList.add('navDown')
			}
		}

		lastScrollTop = st;
		if (st === 0) {
			// Scroll Down
			mainWrapper.classList.remove('hasScrolled')
		}
		if (st > 300) {
			mainWrapper.classList.add('hasScrolled--stickMenus')
		}
		else {
			mainWrapper.classList.remove('hasScrolled--stickMenus')
		}
	}

	const navItems = document.getElementsByClassName("navigation__item--hasChild");

	const closeAllItems = () => {
		// console.log(closeAllItems);
		for (let index = 0; index < navItems.length; index++) {
			const item = navItems[index];
			item.classList.remove('navigation__item--hasChild--open');
		}
	}

	const openMenuItem = (parent) => {
		closeAllItems()
		parent.classList.add('navigation__item--hasChild--open');

		window.document.addEventListener('click', function (event) {
			const isClickOnCurrentMenuItem = parent.contains(event.target);
			if (!isClickOnCurrentMenuItem) {
				//Click outside curren menu item
				parent.classList.remove('navigation__item--hasChild--open');
			}
		});

		window.document.onkeydown = function (evt) {
			evt = evt || window.event;
			var isEscape = false;
			if ("key" in evt) {
				isEscape = (evt.key === "Escape" || evt.key === "Esc");
			} else {
				isEscape = (evt.keyCode === 27);
			}
			if (isEscape) {
				closeAllItems()
			}
		};

	}

	for (let index = 0; index < navItems.length; index++) {
		const parent = navItems[index];
		const trigger = parent.getElementsByClassName("navigation__link")[0]
		const child = parent.getElementsByClassName("navigation__children")[0];
		const allChild = document.getElementsByClassName("navigation__children");
		const back = child.getElementsByClassName("navigation__link--back")[0]

		const grandSons = child.getElementsByClassName("navigation__grandsonsHolder");

		for (let index = 0; index < grandSons.length; index++) {
			const grandSon = grandSons[index];
			const trigger = grandSon.getElementsByClassName("navigation__grandsons-label");

			for (let index = 0; index < trigger.length; index++) {
				const element = trigger[index];

				element.addEventListener('click', (el) => {
					for (let index = 0; index < grandSons.length; index++) {
						const element = grandSons[index];
						element.classList.remove('navigation__grandsonsHolder--open')
					}
					grandSon.classList.add('navigation__grandsonsHolder--open')

				})
			}
		}

		trigger.addEventListener('click', (el, idx) => {
			openMenuItem(parent);

			for (let index = 0; index < allChild.length; index++) {
				const element = allChild[index];
				element.classList.remove('navigation__children--open')
			}
			child.classList.add('navigation__children--open')
			back.addEventListener('click', () => {
				child.classList.remove('navigation__children--open')
			})
		})
	}
};

const Header = ({ siteTitle, activeItem, cartIcon, userIcon, isUserLoggedIn, currentUser, loginUrl, locale }) => {

	const query = graphql`
  {
    allNavigationJson {
      edges {
        node {
          locale
          header {
            menu {
              link
              name
              debug
              push {
                items {
                  icon
                  link
                  name
                }
              }
              promo {
                type
                link
                title
                text
                cta
                pre_title
                image
              }
              children {
                children {
                  label
                  link
                }
                icon
                label
              }
            }
          }
        }
      }
    }
    navigationJson {
		header {
		  menu {
			link
			name
			debug
			push {
			  items {
				icon
				link
				name
			  }
			}
			promo {
			  type
			  link
			  title
			  text
			  cta
			  pre_title
			  image
			}
			children {
			  children {
				label
				link
			  }
			  icon
			  label
			}
		  }
		}
	  }
	  globalsJson(socials: {}) {
		socials {
		  name
		  link
		}
	  }
  }
`
	const dataJson = useStaticQuery(
		query
	);

	// const { dataJson } = globals.globalsJson;
	const { socials } = dataJson.globalsJson;
	const { allNavigationJson } = dataJson;
	const lang = locale ? locale : 'it_IT';
	const navigationJson = allNavigationJson.edges.find(edge => edge.node.locale === lang).node;

	const [eventListenerCreated, setEventListenerCreated] = useState(false);
	useEffect(Menu);
	useEffect(()=> {
		if (!eventListenerCreated) {
			WhereToBuy();
			setEventListenerCreated(true)
		}
	});

	return (
		<div id="header" className={`header ${cartIcon === true ? 'hasCart' : ''} ${userIcon === true ? 'hasUser' : ''}`}>
			<div className="container">
				<div className="row">
					<Hamburger />
					<div className="header__logo">
						<Link className="header__link" to="/">{siteTitle}</Link>
					</div>
					<div className="navigation">
						<ul className="navigation__list">
							<li className="navigation__search">
								<form>
									<Input
										id="navigation__search"
										label=""
										placeholder="Cerca..."
										type="text"
									/>
									<button className="navigation__search-submit" type="submit" value="Cerca">Cerca</button>
								</form>
							</li>
							{navigationJson.header.menu.map((item, key) => (
								<li className={`navigation__item ${key === activeItem ? `active` : ''} ${item.debug === true ? 'debug' : ''} ${item.children ? `navigation__item--hasChild` : ''}`} key={key}>
									{
										item.children ?
											<span className="navigation__link">{item.name}{item.children ? <><i className="htrigger"></i><i className="hcontrol"></i></> : null}</span>
											: <Link className="navigation__link" activeClassName="navigation__link--active" to={item.link}>{item.name}</Link>
									}

									{
										item.children ?
											<ul className="navigation__children">
												<li className="navigation__link navigation__link--back">
													<span>{item.name}</span>
												</li>
												{
													item.children.map((child, key) => (
														<li className={`navigation__grandsonsHolder ${key === 0 ? 'navigation__grandsonsHolder--open' : ''}`} key={key}>
															<span className="navigation__grandsons-label">
																<Icon name={child.icon} color={"blue"} />
																{child.label}
															</span>
															{
																child.children ?
																	<ul className="navigation__grandsons">
																		{
																			child.children.map((grandson, key) => (
																				<li key={key}>
																					<Link to={grandson.link}>{grandson.label}</Link>
																				</li>
																			))
																		}
																	</ul>
																	: null
															}
														</li>
													))
												}
												{
													item.push || item.promo ?
														<li className="navigation-editorial-content">
															{item.push ?
																<Push items={item.push.items} />
																: null}
															{item.promo ?
																<span className="navigation-promo">
																	{item.promo.map((item, i) => (
																		<Promo key={i} {...item} />
																	))}
																</span>
																: null}
														</li>
														: null
												}
												<li className="navigation__heightController">
													<span className="holder">
														{
															item.children ?
																item.children.map((child, key) => (
																	child.children ?
																		<ul className="navigation__grandsons">
																			{
																				child.children.map((grandson, key) => (
																					<li key={key}>
																						<span aria-hidden="true">{grandson.label}</span>
																					</li>
																				))
																			}
																		</ul>
																		: null
																))
																: null
														}
													</span>
												</li>
											</ul>
											: null
									}
								</li>
							))}
						</ul>
						{socials ?
							<ul className="navigation__socials">
								{
									socials.map((channel, i) => (
										<li key={i}>
											<a aria-label={channel.name} href={channel.link} target="_blank" rel="noreferrer" className={`icon icon-social-${channel.name}`}>
												<i aria-hidden="true" />
											</a>
										</li>
									))
								}
							</ul>
							: null}

					</div>
					<div className="header__tools">
						<ul>
							{
								cartIcon === true ?
									<li>
										<span id="cart"></span>
									</li>
									: null
							}
							{
								userIcon === true ?
									<li>
										{
											isUserLoggedIn === false ?
												<a href={loginUrl}>
													<span id="user"></span>
												</a>
												: <span id="user" className="userLoggedIn">
													{
														isUserLoggedIn && currentUser ?
															<>
																<span className="alias">
																	{currentUser.userAlias}
																</span>
																<span className="userNav">
																	<ul>
																		<li className="username">
																			{currentUser.userName}
																		</li>
																		{
																			currentUser.userMenu ?
																				currentUser.userMenu.map((item, i) => (
																					<li key={i}>
																						<Link to={item.link}>{item.name}</Link>
																					</li>
																				))
																				: null
																		}
																		<li className="logout">
																			<Link to={currentUser.logoutLink.link}>{currentUser.logoutLink.name}</Link>
																		</li>
																	</ul>
																</span></>
															: null
													}

												</span>

										}

									</li>
									: null
							}
							<li>
								<span id="searchBtn"></span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div >
	);
}

export default Header;

