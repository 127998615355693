import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

// Fine tune this on project needs
const INTERNAL_REGEX = new RegExp(`^/(?!/)`);

const GlobalLink = ({ to, children, ...props }) => {
	if (INTERNAL_REGEX.test(to)) {
		const { className, activeClassName, style, activeStyle } = props;

		// Gestione activeClassName indipendente da slash finale o meno
		const isActive = ({ isPartiallyCurrent, href, location }) => {
			const pathname = location.pathname;
			const strippedHref = href.endsWith('/') ? href.substring(0, href.length - 1) : href;
			const strippedPathname = pathname.endsWith('/') ? pathname.substring(0, pathname.length - 1) : pathname;
			if (strippedHref === strippedPathname) {
				return {
					className: [className, activeClassName].filter(Boolean).join(" "),
					style: { ...style, ...activeStyle }
				};
			}
			return null;
		}
		return (
			<GatsbyLink getProps={isActive} to={to} {...props}>
				<>
					{children}
				</>
			</GatsbyLink>
		);
	}
	// Remove activeClassName prop from external links
	const { activeClassName, ...other } = props;
	return (
		<a
			href={to}
			rel="noopener noreferrer"
			{...other}
		>
			<>
				{children}
			</>
		</a>
	);
}

export const Link = GlobalLink;