/**
 * Input
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Input
 * @param {object} props - Props
 */

import React from "react";
// import {pascalCase} from "change-case";


class Input extends React.Component {

	render() {
		// console.log(this.props)
		// ${pascalCase(type)}
		const { type, required, className, id, label, placeholder } = this.props
		return (
			<div className={`input TextField ${className}`} id={"input"}>
				{label && id ?
					<label for={id}>{label}{required === true ? <sup>*</sup> : null}</label>
					: null}
				<input id={id} type={type} placeholder={placeholder} required={required} />
			</div>
		);
	}

}

export default Input;
