/**
 * Footer
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class Footer
 * @param {object} props - Props
 */

import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { Link } from '../../atoms/link';
import Nav from '../../../data/navigation/it_IT.json';
import NavGR from '../../../data/navigation/el_GR.json';
import FooterMenuColumn from '../../atoms/footerMenuColumn';
import FooterNewsletter from '../../atoms/footerNewsletter';
import Icon from "../../atoms/icon"
import VideoOverlay from "../../components/video-overlay"


const Footer = ({ siteTitle, navigationJson, navigationJsonGR, noMarginFooter, locale = 'it_IT' }) => {
  const globals = useStaticQuery(
    graphql`
			query {
				globalsJson {
					socials {
					  link
					  name
					}
				}
			}`
  );

  const { socials } = globals.globalsJson;

  const nav = locale === 'el_GR' ? navigationJsonGR : navigationJson;


  return (
    <footer className={`${noMarginFooter === true ? 'no-margin' : 'margin'}`}>
      <VideoOverlay />
      <FooterNewsletter
        title={nav.footer.newsletter[0].title}
        description={nav.footer.newsletter[0].description}
        placeholder={nav.footer.newsletter[0].placeholder}
        button={nav.footer.newsletter[0].button}
      />
      <div className={"socials"}>
        <div className={'container'}>
          <div className={"socials__container"}>
            {socials.map((item, idx) => (
              <h3 key={idx} className={`socials__icon`}>
                <Link key={idx} className={`socials__link icon icon-socials-${item.name}`} to={item.link}>
                  <Icon
                    className="storybook"
                    color="blue"
                    name={`social-${item.name}`}
                  />
                </Link>
              </h3>
            ))}
          </div>
        </div>
      </div>
      <div className={"container"}>
        <div className="menu">
          <div className="menu__list">
            {nav.footer.menu.filter((item, index) => [0, 1, 2, 3].includes(index)).map((item, idx) => (
              <FooterMenuColumn key={idx} link={item.link} name={item.name} children={item.children} />
            ))}
          </div>
          <div className="menu__list halfTop">
            {nav.footer.menu.filter((item, index) => [4].includes(index)).map((item, idx) => (
              <FooterMenuColumn key={idx} link={item.link} name={item.name} children={item.children} />
            ))}
          </div>
          <div className="menu__list halfTop">
            {nav.footer.menu.filter((item, index) => [6].includes(index)).map((item, idx) => (
              <FooterMenuColumn key={idx} link={item.link} name={item.name} children={item.children} />
            ))}
          </div>
          <div className="menu__list halfBottom">
            {nav.footer.menu.filter((item, index) => [5].includes(index)).map((item, idx) => (
              <FooterMenuColumn key={idx} link={item.link} name={item.name} children={item.children} />
            ))}
          </div>
          <div className="menu__list halfBottom">
            {nav.footer.menu.filter((item, index) => [7].includes(index)).map((item, idx) => (
              <FooterMenuColumn key={idx} link={item.link} name={item.name} children={item.children} />
            ))}
          </div>
          <div className="menu__list">
            <div
              className="menu__logo">
              <Link
                className="menu__link"
                to="/"
              >{siteTitle}</Link>
            </div>
          </div>

        </div>
      </div>
      <div className={"copyright"}>
        <div className={'container'}>
          <div className={"copyright__container"}>
            <div>
              <p>{nav.footer.copyright[0].text}</p>
            </div>
            <div>
              <span id={'footerLanguageSwitch'}>
                <Link className="menu__link" to={'/change-language'}><em>{nav.footer.languageSwitch.label}</em></Link>
              </span>
              {nav.footer.copyright[0].link?.map((item, idx) => (
                <Link key={idx} className="menu__link" to={item.url}>{item.name}</Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};


Footer.propTypes = {
  nav: PropTypes.object,
};

// document prop defaults here
Footer.defaultProps = {
  siteTitle: 'Candy',
  navigationJson: Nav,
  navigationJsonGR: NavGR,
};

export default Footer;