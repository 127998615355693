/**
 * Sidebar
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class Sidebar
 * @param {object} props - Props
 */

import React, { useEffect } from "react";


const StickySidebar = () => {
    // // get the sticky element
    // const stickyElm = document.getElementById('sidebar')
    // console.log(stickyElm)

    // const observer = new IntersectionObserver( 
    //   ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
    //   {threshold: [1]}
    // );
    // observer.observe(stickyElm)
}


const Sidebar = ({ title, children, hasFilters, sidebarSticky }) => {
    // useEffect(StickySidebar);

    return (
        <aside id={'sidebar'} className={`sidebar ${hasFilters === true ? 'hasFilters' : ''} ${sidebarSticky === true ? 'sidebarSticky' : ''}`}>
            <div id="sidebar__mobile-filters-close" className="sidebar__mobile-filters-close"></div>
            <h1>{title}</h1>
            <div className="sidebar__children">
                {children}
            </div>
        </aside>
    );
}

export default Sidebar;

