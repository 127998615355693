import React, { useEffect, useRef } from 'react';
import { eventuallyInsertReevooScript } from '../../utility/reevoo';


const ReevooEmbeddable = ({ productId, type = 'tabbed' }) => {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;
    if (container && productId) {
      // console.log('Create reevoo embeddable: ', productId);
      const embeddable = document.createElement('reevoo-embeddable');
      embeddable.setAttribute('type', type);
      embeddable.setAttribute('product-id', productId);
      embeddable.setAttribute('locale', 'it-IT');
      embeddable.setAttribute('per-page', 5);
      container.appendChild(embeddable);
      eventuallyInsertReevooScript('CDD-IT');
      return () => container.removeChild(embeddable);
    }
  }, [productId, type]);

  return (
    <div className="reevoo-embeddable container">
      <div ref={containerRef}></div>
    </div>
  );
}

export default React.memo(ReevooEmbeddable);