import React, { Fragment } from "react";
import { Link } from '../../atoms/link';
class FooterMenuColumn extends React.Component {

	render() {
		return (
			<Fragment>
				<div>
					{this.props.link ?

						<Link className="menu__title" to={this.props.link}>
							{this.props.name}
							<em></em>
						</Link>

						:
						<span className="menu__title">{this.props.name}</span>

					}
				</div>
				{this.props.children ?
					<ul>
						{this.props.children?.map((x, i) => (
							<li key={i}>
								<Link key={i} to={x.link}>{x.name}</Link>
							</li>
						))}
					</ul>
					:
					null
				}
			</Fragment>
		);
	}

}

export default FooterMenuColumn;
