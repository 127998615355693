let reevooScriptInserted = false;

const insertReevooScript = (accountCode) => {
  console.log('Appending reevoo script: ', accountCode || 'CDD-IT');
  
  const reevooScript = document.createElement('script');
  reevooScript.id = 'reevoomark-loader';
  reevooScript.setAttribute('src', `https://widgets.reevoo.com/loader/${accountCode || 'CDD-IT'}.js`);
  reevooScript.setAttribute('async', '');
  reevooScript.setAttribute('defer', '');
  reevooScript.setAttribute('disable-cookie', 'all');
  document.head.appendChild(reevooScript);
}

export const eventuallyInsertReevooScript = (accountCode) => {
  if (!reevooScriptInserted) {
    reevooScriptInserted = true;
    insertReevooScript(accountCode);
  }
}