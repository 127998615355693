/**
 * Hamburger
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Hamburger
 * @param {object} props - Props
 */

import React from "react";

const getElement = (id) => document.getElementById(id);

function mobileMenu(e) {
	const allChild = document.getElementsByClassName("navigation__children");
	for (let index = 0; index < allChild.length; index++) {
		const element = allChild[index];
		element.classList.remove('navigation__children--open')
	}
	const mainWrapper = getElement('main')
	mainWrapper.classList.toggle("mobileMenuVisible");
}


class Hamburger extends React.Component {
	componentDidMount() {
	}

	render() {
		return (
			<div aria-hidden="true" id="headerHamburger" className="header__hamburger" onClick={mobileMenu} onKeyDown={mobileMenu}>
				<span></span>
			</div>
		);
	}

}

export default Hamburger;
