/**
 * WhereToBuy
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class WhereToBuy
 * @param {object} props - Props
 */

import React, { useEffect } from 'react';

const getElement = (id) => document.getElementById(id);
const WhereToBuy = () => {
	
	document.addEventListener("click", (e) => {
		if (
			e.target.classList.contains("cs_content-buylocally-toggle-map")
		) {
			const csmodalbox = e.target.closest(".cs_content-buylocally");

			if (csmodalbox.classList.contains('cs_content-buylocally--map-hidden')) {
				csmodalbox.classList.remove('cs_content-buylocally--map-hidden')
			}
			else {
				csmodalbox.classList.add('cs_content-buylocally--map-hidden')
			}
		}
	})
	return;
}

export default WhereToBuy;
