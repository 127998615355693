/**
 * HeaderPromo
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class HeaderPromo
 * @param {object} props - Props
 */

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

class HeaderPromo extends React.Component {

	render() {
		const { type, link, title, text, cta, pre_title, image } = this.props;

		return (

			<a href={link} className={`navigation-promo__item ${type}`}>
				{
					type === 'text_and_image' && image ?
						<span className="navigation-promo__image" style={{
							backgroundImage: `url("${image}")`
						}}>
							{/* <StaticImage src={image} alt="A dinosaur" /> */}
						</span>
						: null
				}
				<span className="inner">
					{
						type === 'text_and_image' && pre_title ?
							<span className="navigation-promo__pretitle">
								{pre_title}
							</span>
							: null
					}
					<span className="navigation-promo__title">
						{title}
					</span>
					{
						type === 'text_only' && text ?
							<span className="navigation-promo__excerpt">
								{text}
							</span>

							: null
					}
					{
						type === 'text_only' && cta ?
							<span className="navigation-promo__cta">
								{cta}
								<span className="icon"></span>
							</span>

							: null
					}
				</span>
			</a>


		);
	}

}

export default HeaderPromo;
