/**
 * HeaderPush
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class HeaderPush
 * @param {object} props - Props
 */

import React from "react";
import Icon from "../icon";


class HeaderPush extends React.Component {
	render() {
		const { items } = this.props;
		const { rowsLayout } = this.props;
		return (
			<>
				{
					items ?

						<span className="navigation-push">
							<ul className={`navigation-push__items ${items.length % 2 || rowsLayout === true ? 'isOdd' : ''}`}>
								{
									items.map((item, key) => (
										<li className={`navigation-push__item`} key={key}>
											<a href={item.link}>
												<Icon name={item.icon} color={'blue'} />
												{item.name}
											</a>
										</li>
									))
								}
							</ul>
						</span>

						: null
				}
			</>
		);
	}
}

export default HeaderPush;
