import React from 'react'
import PropTypes from 'prop-types'
import Header from '../../modules/header'
import Footer from '../../modules/footer'
import Breadcrumbs from "../../components/breadcrumbs";
import Sidebar from "../../modules/sidebar";
import ReevooEmbeddable from "../../components/reevoo/reevoo-embeddable";

export const compareOverlayClass = 'compare-overlay--open';
export const notificationOverlayClass = 'notification-overlay--open';

const Layout = (
    {
        mainClassName,

        children,
        headerActiveItem,
        breadcrumbs,
        noMarginFooter,

        sidebar,
        sidebarTitle,
        sidebarChildren: SidebarChildren, // ReactComponent
        sidebarChildrenProps,
        sidebarHasFilters,
        sidebarSticky,

        reevooEmbeddable,
        overlay,
        overlayComponents,

        locale = 'it_IT',
        // ...props
    }) => {
    return (
        <main
            locale={locale}
            id="main"
            className={`${mainClassName ? mainClassName : ''} ${overlay ? 'compareOn' : ''} legacyImages`}
        >
            <div className="wrapper">
                <Header locale={locale} userIcon activeItem={headerActiveItem} />
                {
                    breadcrumbs &&
                    <div className={'container'}>
                        <Breadcrumbs items={breadcrumbs} />
                    </div>
                }

                {
                    sidebar ?
                        <div className="hasSidebar">

                            <Sidebar sidebarSticky={sidebarSticky} title={sidebarTitle} hasFilters={sidebarHasFilters}>
                                <SidebarChildren {...sidebarChildrenProps} />
                            </Sidebar>
                            <div className="content">
                                {children}
                            </div>
                        </div> :
                        children
                }
                {
                    reevooEmbeddable ?
                        <ReevooEmbeddable productId={reevooEmbeddable} />
                        : null
                }

                {Array.isArray(overlayComponents) && overlayComponents.map(({ component: Component, props }) => <Component {...props} />)}

                <Footer locale={locale} noMarginFooter={noMarginFooter} />
            </div>
        </main>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout;