/**
 * Breadcrumbs
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Breadcrumbs
 * @param {object} props - Props
 */

import React, { Fragment } from "react";
import { Link } from "../../atoms/link/index";

const Breadcrumbs = ({ items, ...props }) => {
    return (
        <nav>
            <div className="breadcrumbs">
                <ul className="breadcrumbs__items">
                    {items && items.map((item, idx) => (
                        <Fragment key={idx}>
                            <li key={idx} className={`breadcrumbs__item ${!item.link ? 'breadcrumbs__item--disabled' : ''}`}>
                                {/*item.link ? <a href={item.link}>{item.label}</a> : item.label*/}
                                {item.link ? <Link to={item.link}>{item.label}</Link> : <span>{item.label}</span>}
                                {idx < (items.length - 1) ? <em className="breadcrumbs__separator"></em> : null}
                            </li>
                        </Fragment>
                    ))}
                </ul>
            </div>
        </nav>
    );
}

export default Breadcrumbs;
