/**
 * Icon
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Icon
 * @param {object} props - Props
 */

import React from "react";

class Icon extends React.Component {

	render() {
		const name = this.props.name;
		const color = this.props.color;
		
		//custom
		const url = this.props.url;
		const width = this.props.width;
		const height = this.props.height;


		return (
			this.props.name === 'custom' ?
				<span className={`icon custom ${this.props.className}`}>
					<img alt="" src={url} style={{
						width : width,
						height : height,
					}}></img>
				</span>
				: <span className={`icon icon-${name} ${color} ${this.props.className}`}>
					<span className="path1"></span>
					<span className="path2"></span>
				</span>
			// {Selection.icons.map(icon => (

			// ))}
		);
	}
}

export default Icon;
